import React, { useState, useEffect, useRef } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';


export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);


    useEffect(() => {
        const handleScroll = () => {
            const ctaSection = document.getElementById('features');
            console.log(ctaSection)
            if (ctaSection) {
                const ctaSectionPosition = ctaSection.getBoundingClientRect();
                const newIsScrolledValue = ctaSectionPosition.top < -240;
                console.log(ctaSectionPosition.top)
                setIsScrolled(newIsScrolledValue);
            } else {

                // If the CTA section is not found, set isScrolled to false
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className="fixed top-0 z-10 w-full backdrop-filter backdrop-blur dark:text-white">
            <nav className="mx-auto flex px-4 md:px-32 items-center justify-between" aria-label="Global">
                <div className="flex ml-4">
                    <a href="#">
                        <svg width="164" height="76" viewBox="0 0 764 216" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M230.35 0.698917L217.08 212.442L151.307 215.327L151.884 103.686L122.171 193.691H96.7847L73.9948 113.206L73.7063 206.673L7.06773 208.692L9.95252 9.35328L84.0916 6.46849L113.516 101.955L152.75 0.698917H230.35Z" fill="white" />
                            <path d="M364.55 130.9L340.25 214H317.6L302.45 155.95L287.3 214H264.5L240.05 130.9H261.35L276.05 194.2L291.95 130.9H314.15L329.75 194.05L344.45 130.9H364.55ZM386.563 121C382.863 121 379.763 119.85 377.263 117.55C374.863 115.15 373.663 112.2 373.663 108.7C373.663 105.2 374.863 102.3 377.263 100C379.763 97.6 382.863 96.4 386.563 96.4C390.263 96.4 393.313 97.6 395.713 100C398.213 102.3 399.463 105.2 399.463 108.7C399.463 112.2 398.213 115.15 395.713 117.55C393.313 119.85 390.263 121 386.563 121ZM396.913 130.9V214H375.913V130.9H396.913ZM463.711 129.7C473.611 129.7 481.611 132.85 487.711 139.15C493.811 145.35 496.861 154.05 496.861 165.25V214H475.861V168.1C475.861 161.5 474.211 156.45 470.911 152.95C467.611 149.35 463.111 147.55 457.411 147.55C451.611 147.55 447.011 149.35 443.611 152.95C440.311 156.45 438.661 161.5 438.661 168.1V214H417.661V130.9H438.661V141.25C441.461 137.65 445.011 134.85 449.311 132.85C453.711 130.75 458.511 129.7 463.711 129.7ZM553.73 215.35C545.73 215.35 538.53 213.6 532.13 210.1C525.73 206.5 520.68 201.45 516.98 194.95C513.38 188.45 511.58 180.95 511.58 172.45C511.58 163.95 513.43 156.45 517.13 149.95C520.93 143.45 526.08 138.45 532.58 134.95C539.08 131.35 546.33 129.55 554.33 129.55C562.33 129.55 569.58 131.35 576.08 134.95C582.58 138.45 587.68 143.45 591.38 149.95C595.18 156.45 597.08 163.95 597.08 172.45C597.08 180.95 595.13 188.45 591.23 194.95C587.43 201.45 582.23 206.5 575.63 210.1C569.13 213.6 561.83 215.35 553.73 215.35ZM553.73 197.05C557.53 197.05 561.08 196.15 564.38 194.35C567.78 192.45 570.48 189.65 572.48 185.95C574.48 182.25 575.48 177.75 575.48 172.45C575.48 164.55 573.38 158.5 569.18 154.3C565.08 150 560.03 147.85 554.03 147.85C548.03 147.85 542.98 150 538.88 154.3C534.88 158.5 532.88 164.55 532.88 172.45C532.88 180.35 534.83 186.45 538.73 190.75C542.73 194.95 547.73 197.05 553.73 197.05Z" fill="white" />


                        </svg>
                    </a>
                </div>
                <button className="bg-white text-black py-2 px-4 rounded-full hover:bg-blue-700 transition-all duration-500 transform opacity-0 animate-fadeIn"
                    onClick={() => {
                        window.open("https://docs.google.com/uc?export=download&id=1teB5C8ftAAKM_i-26uhwCuyGc_tGt7us", "_blank");
                    }}
                >
                    Download App
                </button>

            </nav>

        </header>
    );
}



