import React from "react";
import { Link, animateScroll as scroll } from 'react-scroll';
// import { Link as Linker } from 'react-router-dom';

// import PrivacyPolicy from './PrivacyPolicy'; // Update the path accordingly


function Footer() {
    return (
        <div className=" bg-primary-blue">
                 <p
                        className="md:hidden px-10 md:px-32 pt-96 text-xl md:text-lg lg:text-xl font-poppins mb-8 text-white transition-all duration-500 transform opacity-0 animate-fadeIn"
                    >
                        Why Mwino?
                    </p>
                <div className="flex overflow-hidden md:hidden flex-wrap gap-2 px-10 md:px-32 transition-all duration-500 transform opacity-0 animate-fadeIn">
                <div className="flex flex-row items-center">
                    <div className="hover:bg-blue-200 transform hover:scale-105 transition-transform duration-300 ease-in-out w-36 h-36 p-3 rounded-md border">
                        <p className="text-xl md:text-lg lg:text-xl font-poppins mb-2 text-white hover:text-black">Sign up to become a mwino agent</p>
                    </div>
                </div>
                <div className="flex flex-row items-center">
                    <div className="hover:bg-green-200 transform hover:scale-105 transition-transform duration-300 ease-in-out w-36 h-36 p-3 rounded-md border">
                        <p className="text-xl md:text-lg lg:text-xl font-poppins mb-2 text-white hover:text-black">Lend our money to women</p>
                    </div>
                </div>
                <div className="flex flex-row items-center">
                    <div className="hover:bg-purple-200 transform hover:scale-105 transition-transform duration-300 ease-in-out w-36 h-36 p-3 rounded-md border">
                        <p className="text-xl md:text-lg lg:text-xl font-poppins mb-2 text-white hover:text-black">Earn commission and be free</p>
                    </div>
                </div>
            </div>
            <div className="w-full h-full">
                <div className="container mx-auto w-10/12 py-12">
                    <div className="xl:flex lg:flex md:flex pt-2">
                        <div className="w-11/12 xl:w-3/6 lg:w-2/5 mx-auto lg:mx-0 xl:mx-0">
                            <div className="flex items-center mb-6 xl:mb-0 lg:mb-0">
                                <svg width="164" height="116" viewBox="0 0 764 216" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M230.35 0.698917L217.08 212.442L151.307 215.327L151.884 103.686L122.171 193.691H96.7847L73.9948 113.206L73.7063 206.673L7.06773 208.692L9.95252 9.35328L84.0916 6.46849L113.516 101.955L152.75 0.698917H230.35Z" fill="white"/>
<path d="M364.55 130.9L340.25 214H317.6L302.45 155.95L287.3 214H264.5L240.05 130.9H261.35L276.05 194.2L291.95 130.9H314.15L329.75 194.05L344.45 130.9H364.55ZM386.563 121C382.863 121 379.763 119.85 377.263 117.55C374.863 115.15 373.663 112.2 373.663 108.7C373.663 105.2 374.863 102.3 377.263 100C379.763 97.6 382.863 96.4 386.563 96.4C390.263 96.4 393.313 97.6 395.713 100C398.213 102.3 399.463 105.2 399.463 108.7C399.463 112.2 398.213 115.15 395.713 117.55C393.313 119.85 390.263 121 386.563 121ZM396.913 130.9V214H375.913V130.9H396.913ZM463.711 129.7C473.611 129.7 481.611 132.85 487.711 139.15C493.811 145.35 496.861 154.05 496.861 165.25V214H475.861V168.1C475.861 161.5 474.211 156.45 470.911 152.95C467.611 149.35 463.111 147.55 457.411 147.55C451.611 147.55 447.011 149.35 443.611 152.95C440.311 156.45 438.661 161.5 438.661 168.1V214H417.661V130.9H438.661V141.25C441.461 137.65 445.011 134.85 449.311 132.85C453.711 130.75 458.511 129.7 463.711 129.7ZM553.73 215.35C545.73 215.35 538.53 213.6 532.13 210.1C525.73 206.5 520.68 201.45 516.98 194.95C513.38 188.45 511.58 180.95 511.58 172.45C511.58 163.95 513.43 156.45 517.13 149.95C520.93 143.45 526.08 138.45 532.58 134.95C539.08 131.35 546.33 129.55 554.33 129.55C562.33 129.55 569.58 131.35 576.08 134.95C582.58 138.45 587.68 143.45 591.38 149.95C595.18 156.45 597.08 163.95 597.08 172.45C597.08 180.95 595.13 188.45 591.23 194.95C587.43 201.45 582.23 206.5 575.63 210.1C569.13 213.6 561.83 215.35 553.73 215.35ZM553.73 197.05C557.53 197.05 561.08 196.15 564.38 194.35C567.78 192.45 570.48 189.65 572.48 185.95C574.48 182.25 575.48 177.75 575.48 172.45C575.48 164.55 573.38 158.5 569.18 154.3C565.08 150 560.03 147.85 554.03 147.85C548.03 147.85 542.98 150 538.88 154.3C534.88 158.5 532.88 164.55 532.88 172.45C532.88 180.35 534.83 186.45 538.73 190.75C542.73 194.95 547.73 197.05 553.73 197.05Z" fill="white"/>


                                </svg>
                            </div>

                        </div>
                        <div className="w-11/12 xl:w-1/6 lg:w-2/5 mx-auto lg:mx-0 xl:mx-0 pt-3 xl:flex xl:justify-end pl-3 sm:pl-0">
                            {/* <ul>
                                <li className="text-white font-bold text-xl mb-6">Products</li>
                                <li className="text-base text-slate-100 hover:text-white mb-5">
                                    <Link
                                        activeClass="active"
                                        to="rent-financing"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Rent Financing
                                    </Link>
                                </li>
                                <li className="text-base text-slate-100 hover:text-white mb-5">
                                    <Link
                                        activeClass="active"
                                        to="marketplace"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Market Place
                                    </Link>
                                </li>
                                <li className="text-base text-slate-100 hover:text-white mb-5">
                                    <Link
                                        activeClass="active"
                                        to="roommate"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Roommate
                                    </Link>
                                </li>

                            </ul> */}
                        </div>


                    </div>
                    <div className="xl:flex flex-wrap justify-between xl:mt-8 mt-8">

                        <div className="flex flex-col md:flex-row justify-between w-full">
                            <div className="w-11/12 xl:w-1/6 mx-auto lg:mx-0 xl:mx-0  xl:mb-0">
                                <p className="text-slate-400 text-base mb-2">© 2024 Mwino Cash</p>
                                <p className="text-slate-400 text-base hover:text-white">All rights reserved | All wrongs reserved.</p>
                            </div>

                            <div className="w-11/12 xl:w-1/3 mx-auto mt-6 md:mt-0 lg:mx-0 xl:mx-0 xl:mb-0">
                                <ul>
                                    <li className="text-white font-bold text-xl mb-2">Contact Us</li>
                                    <li className="text-base text-slate-400 hover:text-white">
                                        Ntinda Complex Bldg, 2nd Floor, <br/> P.O Box 26230, Ntinda Road, Kampala, Uganda.<br/>Email: info@mwino.cash
                                    </li>

                                </ul>
                            </div>
                        </div>




                     
                        {/* <div className="w-11/12 xl:w-1/6 lg:w-1/6 sm:w-11/12 mx-auto lg:mx-0 xl:mx-0 mb-6 lg:mb-0 xl:mb-0 mt-8 lg:mt-8 xl:mt-0">
                            <div className="flex justify-start sm:justify-start xl:justify-end space-x-6 pr-2 xl:pr-0 lg:pr-0 md:pr-0 sm:pr-0">
                                <div>
                                    <a href="#">
                                        <svg aria-label="Twitter" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="#718096" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-twitter">
                                            <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
                                        </svg>
                                    </a>
                                </div>
                                <div>
                                    <a href="#">
                                        <svg aria-label="Instagram" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="#718096" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram">
                                            <rect x={2} y={2} width={20} height={20} rx={5} ry={5} />
                                            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                                            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;





