import logo from './logo.svg';
import './App.css';
import HomeSection from './landingPage/HomeSection';
import Header from './landingPage/Header';
import Footer from './landingPage/Footer';

function App() {
  return (
    <div className="w-full ">
      <Header />
      <HomeSection />
      <Footer />
    </div>
  );
}

export default App;
