import React, { useState, useEffect } from 'react';
// import { Link, animateScroll as scroll } from 'react-scroll';
import '../App.css';

const HomeSection = () => {
    const usps = [
        'Get some ka Money, Make some ka Money',
        'Money for Women, by Women, to Empower Women'
    ];

    const mobilePicSlider = [
        'https://ik.imagekit.io/ecjzuksxj/mwino/Pixel%207%20(2).png',
        'https://ik.imagekit.io/ecjzuksxj/mwino/agent_profile_small_android.png'
    ];

    const [currentUspIndex, setCurrentUspIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentUspIndex((prevIndex) => (prevIndex + 1) % usps.length);
        }, 6000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="h-screen w-full flex items-center bg-primary-blue overflow-hidden">

            <div className="absolute xl:relative h-full w-full">
                <img className="h-[50rem] md:h-full w-full md:w-[63rem] object-cover transition-transform duration-300 transform hover:scale-105" src="https://firebasestorage.googleapis.com/v0/b/glamour-51c77.appspot.com/o/getstarted_bg.jpg?alt=media&token=b00c2444-985c-453e-aec2-03a3b4a2d2b2" />
                <div className="absolute top-0 left-0 h-[50rem] md:h-full w-full md:w-[63rem] bg-black opacity-50"></div>
            </div>

            <div className="flex flex-col md:flex-row px-4 md:px-32 justify-center items-center h-full">


                <div className="absolute top-48 px-10 md:px-32 max-w-7xl -left-[calc(0%+0.4rem)] ">
                    <h1 className="leading-tight md:leading-tight lg:leading-tight xl:leading-tight text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-black font-inter mb-8 text-white transition-all duration-500 transform opacity-0 animate-fadeIn">
                        Get Mwino, <br />Do that Gig Now<br />
                        Earn Commission
                    </h1>

                    <p
                        className=" md:text-lg lg:text-2xl font-poppins font-normal mb-8 text-white transition-all duration-500 transform opacity-0 animate-fadeIn"
                    >
                        {usps[currentUspIndex]}
                    </p>

                    <div className=" absolute flex flex-col md:flex-row">
                        <img
                            className="hidden md:flex relative md:absolute overflow-hidden top-[calc(28%+3rem)] md:top-[calc(16%+3rem)] md:left-[calc(46%+3rem)] w-[18.725rem] md:w-[22.725rem] xl:w-[22.725rem] object-cover md:hover:scale-110 transition-transform duration-300"
                            src="https://ik.imagekit.io/ecjzuksxj/mwino/Pixel%207%20(2).png?updatedAt=1704983078458"
                            alt="Overlay Image"
                        />
                    </div>
                    <p
                        className="hidden md:flex mt-32 text-xl md:text-lg lg:text-xl font-poppins mb-8 text-white transition-all duration-500 transform opacity-0 animate-fadeIn"
                    >
                        Why Mwino?
                    </p>

                    <div className="hidden md:flex flex-col md:flex-row py-4 md:py-4 transition-all duration-500 transform opacity-0 animate-fadeIn">
                        <div className="flex flex-row items-center mb-4 md:mr-4">
                            <div className="hover:bg-blue-200 transform hover:scale-105 transition-transform duration-300 ease-in-out w-40 h-40 p-4 rounded-md border">
                                <p className="text-xl md:text-lg lg:text-xl font-poppins mb-2 text-white hover:text-black">Sign up to become a mwino agent</p>
                            </div>
                        </div>
                        <div className="flex flex-row items-center mb-4 md:mr-4">
                            <div className="hover:bg-green-200 transform hover:scale-105 transition-transform duration-300 ease-in-out w-40 h-40 p-4 rounded-md border">
                                <p className="text-xl md:text-lg lg:text-xl font-poppins mb-2 text-white hover:text-black">Lend our money to women</p>
                            </div>
                        </div>
                        <div className="flex flex-row items-center mb-4 md:mr-4">
                            <div className="hover:bg-purple-200 transform hover:scale-105 transition-transform duration-300 ease-in-out w-40 h-40 p-4 rounded-md border">
                                <p className="text-xl md:text-lg lg:text-xl font-poppins mb-2 text-white hover:text-black">Earn commission and be free</p>
                            </div>
                        </div>
                    </div>


                </div>


                <img
                className="hidden lg:flex absolute overflow-hidden px-10 top-[calc(54%+3rem)] md:top-[calc(8%+3rem)] md:right-[calc(3%+3rem)] w-[25.725rem] md:w-[30.725rem] lg:w-[24.725rem] xl:w-[29.725rem] object-cover md:hover:scale-110 transition-transform duration-300"
                src={mobilePicSlider[currentUspIndex]}

            />
                {/* <img className="hidden md:block absolute top-[calc(40%+3rem)] md:top-[calc(8%+3rem)]  left-[calc(45%+3rem)] md:left-[calc(68%+3rem)] w-[12.725rem] md:w-[22.725rem] xl:w-[21.525rem] transition-transform duration-300 transform hover:scale-110" src="https://ik.imagekit.io/ecjzuksxj/mwino/agent_profile.png" /> */}
            </div>

            {/* <img
                className="hidden md:flex absolute overflow-hidden px-10 top-[calc(54%+3rem)] md:top-[calc(16%+3rem)] md:left-[calc(46%+3rem)] w-[25.725rem] md:w-[25.725rem] xl:w-[27.725rem] object-cover md:hover:scale-110 transition-transform duration-300"
                src="https://ik.imagekit.io/ecjzuksxj/mwino/Pixel%207%20(2).png"

            /> */}

            <img
                className="md:hidden absolute overflow-hidden px-10 top-[calc(54%+3rem)] md:top-[calc(16%+3rem)] md:left-[calc(46%+3rem)] w-[25.725rem] md:w-[25.725rem] xl:w-[27.725rem] object-cover md:hover:scale-110 transition-transform duration-300"
                src={mobilePicSlider[currentUspIndex]}

            />

        </div>
    );
};

export default HomeSection;